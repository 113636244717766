import { ParamMap } from "@angular/router";
import { debug as factory } from "debug";
import { PARAM_APPLICATION_ID, PARAM_TALKDESK_ID } from "./app.enums";
import { addBreadcrumb, SeverityLevel } from "@sentry/angular-ivy";
import { FormGroup } from "@angular/forms";
import { decodeBase64 } from "../core-ui";

export const createSentryLogger =
  (category: string) =>
  (message: any, level: SeverityLevel = "info") => {
    try {
      addBreadcrumb({
        category,
        message: JSON.stringify(message),
        level,
      });
    } catch {}
  };

export const debug = factory("auvious::integration");
export const debugError = factory("auvious::integration:error");
debugError.log = console.error.bind(console);

export function discoverApplicationId(
  queryParamMap: ParamMap | URLSearchParams
): string {
  if (queryParamMap.has(PARAM_APPLICATION_ID)) {
    return queryParamMap.get(PARAM_APPLICATION_ID);
  }

  if (queryParamMap.has(PARAM_TALKDESK_ID)) {
    const id = queryParamMap.get(PARAM_TALKDESK_ID);
    return id ? `talkdesk_${id}` : null;
  }

  if (queryParamMap.has("state")) {
    try {
      const state = JSON.parse(decodeBase64(queryParamMap.get("state"))) as any;
      return state.applicationId;
    } catch {}
  }

  return null;
}

/** create bundles from angular.json or provile URL */
export async function loadScript(name: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = name;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
}

export function markFormFields(form: FormGroup) {
  for (const key in form.controls) {
    if (key in form.controls) {
      form.controls[key].markAsTouched();
    }
  }
}

export function mapToJSON(map: Map<any, any>): object {
  return Array.from(map.entries()).reduce((o, [key, value]) => {
    o[key] = value;

    return o;
  }, {});
}
