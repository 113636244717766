import { IOAuth2Token } from "@auvious/common";
import { UserCapabilityEnum, UserRoleEnum } from "../core-ui.enums";
import { base64UrlToBase64, debug, decodeBase64 } from "../services/utils";
import { IUser } from "./IUser";

export class User implements IUser {
  private id: string;
  private roles: string[];
  private claims: Record<string, any>;
  private licenses: ("freetrial" | "video" | "cobrowse")[];
  private capabilities: UserCapabilityEnum[];
  private authenticated = true;

  constructor(oauth2Token: IOAuth2Token) {
    const base64 = base64UrlToBase64(oauth2Token.accessToken.split(".")[1]);

    this.claims = !!base64 ? JSON.parse(decodeBase64(base64)) : {};

    if ("sub" in this.claims) {
      this.id = this.claims.sub;
    } else if ("userId" in this.claims) {
      this.id = this.claims.userId;
    } else {
      throw new Error("no sub or userId claim found!");
    }
    debug("accessToken: ", this.claims);
    if ("authorities" in this.claims) {
      const authorities = this.claims.authorities as string[];
      this.roles = authorities
        .filter((authority) => authority.startsWith("ROLE_"))
        .map((authority) => authority.substring("ROLE_".length));
    } else {
      this.roles = [];
    }
    this.capabilities = [];

    if ("licenses" in this.claims) {
      this.licenses = this.claims.licenses;
    } else {
      this.licenses = ["freetrial"];
    }
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  unauthenticate() {
    this.authenticated = false;
  }

  getRoles(): UserRoleEnum[] {
    return this.roles.map((role) => role as UserRoleEnum);
  }

  hasRole(role: UserRoleEnum): boolean {
    return !!this.roles.find((value) => value === role);
  }

  addRole(role: UserRoleEnum) {
    this.roles.push(role);
  }

  getId(): string {
    return this.id;
  }

  hasClaim(key: string): boolean {
    return key in this.claims;
  }

  getClaim(key: string): string {
    return this.claims[key];
  }

  setClaim(key: string, value: string) {
    return (this.claims[key] = value);
  }

  addCapability(capability: UserCapabilityEnum) {
    this.capabilities.push(capability);
  }

  hasCapability(capability: UserCapabilityEnum) {
    return !!this.capabilities.find((value) => value === capability);
  }

  getCapabilities() {
    return this.capabilities;
  }

  hasLicense(key: "freetrial" | "video" | "cobrowse") {
    // allow everything on freetrial
    return this.licenses.includes(key) || this.licenses.includes("freetrial");
  }
}
